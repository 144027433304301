import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import sportsLogo from "../assets/img/sports_logo.png"
import educationLogo from "../assets/img/eductation_logo.jpg"
import commsLogo from "../assets/img/comms_logo.jpg"
const Home = () => {
  const history = useHistory();
  const navigate=(page)=>{
    history.push({
        pathname: `/${page}`,
        search: `?type=${page}`,
    });
  }
  return (
    <>
      <Container>
        <Row>
          <Col md={4}>
            <Button onClick={()=>navigate("sports")} color="primary" size="lg">
              Sports
            </Button>{" "}
          </Col>
          <Col md={4}>
            <Button onClick={()=>navigate("communication")}  color="primary" size="lg">
              Comms
            </Button>{" "}
          </Col>
          <Col md={4}>
            <Button onClick={()=>navigate("education")} color="primary" size="lg">
              Education
            </Button>{" "}
          </Col>
        </Row>
        <Row>
          <Col className="justify-content-center" md={4}>
            <h5>
              <a href="https://youtu.be/jpiTIiIixpc">
                https://youtu.be/jpiTIiIixpc
              </a>
            </h5>
          </Col>
          <Col md={4}>
            <h5>
              <a
                href="https://youtu.be/wOhyvX6CmCg"
              >
                https://youtu.be/wOhyvX6CmCg
              </a>
            </h5>
          </Col>
          <Col md={4}>
            <h5>
              <a href="https://youtu.be/BFJCfNTz0ho">
                https://youtu.be/BFJCfNTz0ho
              </a>
            </h5>
          </Col>
        </Row>
        <Row className="mt-3">
        <Col md={4}>
            <img src={sportsLogo}  width={250} height={100} alt="Sports img"></img>
        </Col>
        <Col md={4}>
        <img src={commsLogo} width={250} height={100}  alt="Comms img"></img>
        </Col>
        <Col md={4}>
        <img src={educationLogo} width={250} height={100} alt="Education img"></img>
        </Col>
        </Row>
        <Row className="mt-5">
            <Col md={3}></Col>
            <Col md={4}>
            <Button color="primary" onClick={()=>navigate("invester")} size="lg">
              Investor
            </Button>{" "}  
            </Col>
            <Col md={4}>
            <Button color="primary" onClick={()=>navigate("celebrity")} size="lg">
              Celebrity
            </Button>{" "}  
            </Col>
        </Row>
      </Container>
    </>
  );
};
export default Home;
