import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Form, FormGroup,FormText,Input,Label, Row ,Spinner} from "reactstrap";
import { sendEmail } from "../store/actions/emailAction";



const Communication=()=>{
  const dispatch=useDispatch()
  let { email_loader } = useSelector(state => state.email);
  const history=useHistory()
   const onSubmitHandler=(e)=>{
    e.preventDefault();
   const data= {
      email:e.target[1].value,
      name:e.target[0].value,
      message:e.target[2].value
   }
   dispatch(sendEmail(data)).then((res)=>{
    if(res)
      history.push("/home");
   })
   }
return (
    <>
    <Container>
        <Row>
        <Col md={2}></Col>
        <Col md={8}>
    <Form onSubmit={(e)=>{onSubmitHandler(e)}}>
        <FormGroup>
          <Label for="Name">Name</Label>
          <Input required type="text"  id="text" placeholder="Enter Name" />
        </FormGroup>
        <FormGroup>
          <Label for="exampleEmail">Email</Label>
          <Input required type="email"  id="exampleEmail" placeholder="Enter Email" />
        </FormGroup>
        <FormGroup>
          <Label for="message">Message</Label>
          <Input required type="textarea"  id="message" />
        </FormGroup>
        
        <Button disabled={email_loader} type="submit">
          {email_loader ? (<Spinner></Spinner>): ("Submit")} 
          </Button>
      </Form>
      </Col>
      <Col md={2}></Col>
      </Row>
      </Container>
    </>
)
}
export default Communication;