// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import emailReducer from "./emailReducer";

export let rootReducer = combineReducers({
  authUser: authUserReducer,
  email:emailReducer,
});

export default rootReducer;
