import { Col, Container, Row } from "reactstrap";
import banner from "../assets/img/bobblecast_header_logo.png"

const Main = props => {
	return (
		<>
		<Container className="mb-5">
			<Row className="justify-content-center">
				<img src={banner} alt="logo" />
			</Row>
		</Container>
			{/* <header>Main Header</header> */}
			{props.children}
			{/* <footer>Main Footer</footer> */}
		</>
	);
};

export default Main;
