import axios from "axios";
import { EMAIL_LOADER } from "../types";
// import { Toast } from "react-toastify/dist/components";
import { toast } from "react-toastify";
export const setEmailLoading = val => async dispatch => {
	dispatch({ type: EMAIL_LOADER, payload: val });
};
export const sendEmail = (payload) => async (dispatch) => {
    await dispatch(setEmailLoading(true))
    const data={
        data: payload
    }
    const res=await axios.post('https://us-central1-bobble-cast.cloudfunctions.net/sendEmail',data)
    if(res?.data?.message==="sent"){
        toast.success("Email sent");
        dispatch(setEmailLoading(false))
        return true
    }else{
        toast.warning("Email sending failed");
    dispatch(setEmailLoading(false))
    return false
    }
  };