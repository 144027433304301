import Celebrity from '../components/Celebrity';
import Communication from '../components/Communication';
import Education from '../components/Education';
import Home from '../components/Home';
import Invester from '../components/Investor';
import Sports from '../components/Sports';
import AuthView from '../views/auth/AuthView';
import MainView from '../views/MainView';


let routes = [
	{
		path: '/auth',
		component: AuthView,
		layout: 'auth',
	},
	{
		path: '/',
		component: Home,
		layout: 'main',
	},
	{
		path: '/sports',
		component: Sports,
		layout: 'main',
	},
	{
		path: '/communication',
		component: Communication,
		layout: 'main',
	},
	{
		path: '/education',
		component: Education,
		layout: 'main',
	},
	{
		path: '/invester',
		component: Invester,
		layout: 'main',
	},
	{
		path: '/celebrity',
		component: Celebrity,
		layout: 'main',
	},
];
export default routes;