import { EMAIL_LOADER } from "../types";

const initialState = {
  email_loader: false,
};

/*
    Any action related to Profile will go here.
*/

export default function emailReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case EMAIL_LOADER:
      return {
        ...state,
        email_loader: payload,
      };
    default:
      return { ...state };
  }
}
